import {cn} from "@/libs/utils";

const Button = ({children, className,...rest}) => {
  return (
    <button
      {...rest}
      className={cn(
        "px-5 py-3 rounded-lg font-bold",
        "transition-all duration-500",
        className
      )}
    >
      {children}
    </button>
  );
};

export default Button;