import Container from "@/components/ui/container";

import ObjectItem from "@/components/Objects/ObjectItem";
import ObjectModal from "@/components/Objects/ObjectModal";
import {useState} from "react";
import objectsList  from "./objectsList";
const objects = objectsList

const Objects = () => {
  const [selectedObject, setSelectedObject] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const open = (object) => {
    setSelectedObject(object)
    setIsOpen(true)
  }
  const close = () => {
    setIsOpen(false)
  }
  return (
    <section className={'py-16 bg-primary-50'}>
      <Container>
        <h2 className={'text-4xl'}>Объекты</h2>
        <p className={'text-dark-400 mt-6'}>Мы предлагаем вам уникальные возможности для объектов в самых живописных
          yголках Болгарии. Надежность, прозрачность и высокая доходность — это те характеристики, которые отличают нас
          на рынке.</p>
        <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 mt-10'}>
          {objects.map((object, index) => (
            <ObjectItem key={index} object={object} onClick={() => open(object)}/>
          ))}
        </div>

      </Container>
      <ObjectModal close={close} isOpen={isOpen} object={selectedObject}/>
    </section>
  );
};

export default Objects;