import Button from "@/components/ui/button";
import {cn} from "@/libs/utils";
import ObjectItemFeatureList from "./ObjectItemFeatureList";
import ObjectItemImg from "./ObjectItemImg";
import ObjectItemHeader from "./ObjectItemHeader";

const ObjectItem = ({object, onClick}) => {
  return (
    <div key={object.location} className={'bg-white rounded-xl p-6 group'}>
      <ObjectItemHeader object={object}/>
      <ObjectItemImg object={object}/>
      <ObjectItemFeatureList object={object}/>
      <Button onClick={onClick} className={cn(
        "mt-4 text-base font-normal p3-2 px-6",
        "border border-primary-500 text-primary-500",
        "hover:bg-primary-500 hover:text-white"
      )}>Подробнее об объекте</Button>
    </div>
  );
};

export default ObjectItem;