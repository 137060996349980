import {cn, useGetYmCode} from "@/libs/utils";
import Input from "@/components/ui/input";
import PhoneInput from "@/components/ui/phoneInput";
import {memo, useRef, useState} from "react";
import ButtonPrimary from "@/components/ui/ButtonPrimary";
import Modal from "@/components/ui/modal";
import {SparklesIcon} from "@heroicons/react/24/outline";
import ym from "react-yandex-metrika";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const getAllUtms = () => {
  const utms = {};
  const utmRegex = /utm_(\w+)=([^&]+)/g;
  const url = window.location.href;
  let match;
  while ((match = utmRegex.exec(url)) !== null) {
    utms[match[1]] = match[2];
  }
  return utms;
}

async function sendFormToServer(data) {
  const response = await fetch(`${BASE_URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error('Ошибка при отправке формы');
  }
  return await response.json();
}


const Form = ({className, buttonText = 'Отправить', slug}) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [isSend, setIsSend] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const phoneRef = useRef(null);
  const ymCode = useGetYmCode();
  const [isShake, setIsShake] = useState(false);

  const shake = () => {
    setIsShake(true);
    setTimeout(() => setIsShake(false), 500);
  }
  const clearForm = () => {
    setName('');
    setPhone('');
    phoneRef.current?.getInstance().setNumber("");

  }

  const canSend = !!name && phone && !isSend;

  async function sendForm(e) {
    e.preventDefault();
    setIsSend(true);
    const utms = getAllUtms();
    try {
      const resp = await sendFormToServer({name, phone, slug, ...utms});
      if (!resp?.success) {
        setError(resp?.message || 'Ошибка при отправке формы')
      }
      setIsModalOpen(true);
      clearForm();
      if (ymCode) ym('reachGoal', 'lead');

    } catch (e) {
      console.error(e)
      setError('Ошибка при отправке формы');
    }
    setIsSend(false);
  }

  return (
    <>
      <form onSubmit={sendForm} className={cn(
        "grid grid-cols-1 gap-2",
        className,
      )}>
        {error && <div className={'text-white text-center bg-red-500 rounded-xl p-3'}>{error}</div>}
        <label className={cn( {'animate-shake': isShake && name.length === 0})}>
          <small className={'text-xs mb-2 inline-block text-dark-500'}>
            Введите ваше имя
          </small>
          <Input
            value={name}
            type='text'
            onChange={(e) => setName(e.target.value)}
            required={true}
            placeholder="Например: Владимир"
            className={cn("px-5 py-4 w-full", {"ring-accent-300 ring-2": isShake && name.length === 0})}
          />
        </label>
        <label className={cn('relative',{'animate-shake': isShake && phone.length === 0} )}>
          <small className={'text-xs mb-2 inline-block text-dark-500'}>
            Введите ваш номер телефона
          </small>
          <PhoneInput
            value={phone}
            inputRef={phoneRef}
            setValue={(value) => setPhone(value)}
            className={cn("px-5 py-4 w-full", {"ring-accent-300 ring-2": isShake && phone.length === 0})}/>
        </label>
        <div className={'relative'}>
          {!canSend &&
            <div onClick={shake} className={'absolute w-full h-full top-0 left-0 z-50 cursor-not-allowed'}></div>}
          <ButtonPrimary disabled={!canSend}
                         className={'w-full  py-4 mt-2 text-lg disabled:opacity-30 disabled:cursor-not-allowed'}>
            {isSend ? 'Отправка...' : buttonText}
          </ButtonPrimary>

        </div>

        <small className={'text-xs text-dark-400 text-center'}>
          Нажимая на кнопку "{buttonText}" вы соглашаетесь на обработку персональных данных
        </small>
      </form>
      <Modal isOpen={isModalOpen} close={() => setIsModalOpen(false)}>
        <div className={'py-5'}>
          <SparklesIcon strokeWidth={1} className={'w-16 h-16 text-primary-500 mx-auto mb-2'}/>
          <h3 className={'text-2xl text-dark-700 text-center'}>
            Спасибо за заявку!
          </h3>
          <p className={'text-dark-500 text-center mt-2'}>
            Наши специалисты свяжутся с вами в ближайшее время
          </p>
        </div>
      </Modal>
    </>
  );
};

export default memo(Form);