import {PlayCircleIcon} from "@heroicons/react/24/outline";
import React from "react";
import Modal from "@/components/ui/modal";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const YouTubeModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const close = () => setIsOpen(false);

  const open = () => setIsOpen(true);
  return (
    <>
      <button onClick={open} className={'flex items-center text-accent-500 justify-center space-x-2'}>
        <PlayCircleIcon className={'w-10 h-10'} strokeWidth={'1'}/>
        Смотреть видео презентацию
      </button>
      <Modal isOpen={isOpen} size={'6xl'} close={close}>
        <div className={'rounded-2xl overflow-hidden'}>
          <LiteYouTubeEmbed
            params={
              "modestbranding=0&rel=0&showinfo=0&color=white&controls=0"
            }
          id="ZMpYpNXwyao"
          title={'Видео презентация'}
        />
        </div>

      </Modal>
    </>
  );
};

export default YouTubeModal;