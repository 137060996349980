import React, {memo} from 'react';
import Container from "@/components/ui/container";
import bg from "./header-bg.jpg"
import logo from "@/assets/logo.png";
import Form from "@/components/ui/form";
import CallToAction from "@/components/common/CallToAction";
import YouTubeModal from "@/components/Header/YouTubeModal";

const Header = () => (
    <header className="py-16 relative">

      <img
        src={bg}
        alt={"bg"}
        className={"w-full h-full object-cover absolute top-0 left-0 z-0"}
      />
      <div className={"bg-gradient-to-b from-neutral-950 to-neutral-950/50 w-full h-full object-cover absolute top-0 left-0 z-[1]"}/>
      <Container className={'z-10 relative flex lg:space-x-4 items-center justify-center lg:flex-row flex-col'}>
        <div className={'lg:w-2/3 w-full mb-16'}>
          <a href={'/'}><img src={logo} alt="logo" className={'w-24 mb-4 grayscale'}/></a>
          <h1 className="text-4xl mb-8 text-white">Инвестиции от 2500€ в недвижимость Болгарии с возможностью получения
            ВНЖ/ПМЖ в
            Европе </h1>
          <h2 className={'text-primary-100 text-lg mb-8'}>
            Недвижимость в Болгарии - это выгодное вложение средств в недвижимость, которое приносит стабильный доход и
            обеспечивает долгосрочное сохранение капитала.
          </h2>
          <YouTubeModal/>



        </div>
        <div className={'lg:w-1/3 rounded-lg bg-white w-full py-6 px-5'}>
          <CallToAction
            title={'Получите консультацию по инвестициям в недвижимость в Болгарии'}
          />
          <Form
            className='mt-2' slug='header'
            buttonText={'Получить консультацию'}
          />
        </div>
      </Container>
    </header>

  )
;

export default memo(Header);