import {cn} from "@/libs/utils";

const Container = ({className,children,as: Element='div',...rest}) => {
  return (
    <Element className={cn(className,'container mx-auto px-4')} {...rest}>
      {children}
    </Element>
  );
};

export default Container;