import {
  ArrowsPointingOutIcon, BuildingOfficeIcon,
  CalendarDaysIcon,
  ChartBarIcon,
  ChartPieIcon,
  CurrencyEuroIcon, PresentationChartBarIcon, RocketLaunchIcon
} from "@heroicons/react/24/outline";

const objectsList = [
  {
    title: 'Novi Han',
    type: 'Жилой дом',
    price: '€250 000',
    features: [
      {
        icon: ArrowsPointingOutIcon,
        title: 'Общая площадь',
        value: '194 м2'
      },
      {
        icon: CalendarDaysIcon,
        title: 'Период строительства',
        value: '04.24-11.25'
      },
      {
        icon: ChartPieIcon,
        title: 'Минимальная инвестиция',
        value: '€2500'
      },
      {
        icon: CurrencyEuroIcon,
        title: 'Ожидаемая доходность',
        value: '34% годовых в €'
      }
    ],
    image: require('./images/1.jpg'),
    description: <>
      Просторные двухэтажные коттеджи общей площадью 194 квадратных метра возводятся в городе Нови Хан, всего в 10
      километрах от столицы Болгарии.<br/> На первом этаже расположены гостиная, функциональная кухня и уютный холл,
      создающие отличные условия для семейных вечеров и встреч с друзьями.<br/> Также на первом этаже вы найдете
      веранду, где можно наслаждаться свежим воздухом и красивым видом на природу.<br/> Котельная и подсобное помещение
      обеспечивают дополнительное удобство и функциональность. На втором этаже размещаются две ванные комнаты, три
      уютные спальни и гардероб, создавая идеальные условия для комфортного отдыха.
    </>
  },
  {
    title: 'Усадьба в пос. Зелин',
    type: 'Жилой дом',
    price: '€430 000',

    features: [
      {
        icon: ArrowsPointingOutIcon,
        title: 'Общая площадь',
        value: '200 м2'
      },
      {
        icon: ChartBarIcon,
        title: 'LTV',
        value: '48%'
      },
      {
        icon: ChartPieIcon,
        title: 'Минимальная инвестиция',
        value: '€2500'
      },
      {
        icon: CurrencyEuroIcon,
        title: 'Ожидаемая доходность',
        value: '27% годовых в €'
      }
    ],
    image: require('./images/2.webp'),
    description: "Интересный проект дома общей площадь. 200 квадратных метров, расположенном в живописном районе поселка Зелин , что под Ботевградом.\n" +
      "Необычный ландшафт, заросли лиственных деревьев и тихая местность являются совершенными условиями для расположения отдаленной усадьбы.\n" +
      "Этот проект не только крыша над головой и четыре стены, а настоящий шедевр, проживание в котором будет приносить только удовольствие. Фасад отлично вливается в окружающую среду, особо не влияя на природную гармонию, а плавно сливаясь с ней.\n" +
      "Панорамные окна позволяют сполна использовать дневной свет, который заливает даже самые отдаленные уголки комнат, также это позволило впустить внутрь органический свет, сделав его частью интерьера.\n"
  },
  {
    title: 'Вилла в городе Владая',
    type: 'Жилой дом',
    price: '€500 000',
    features: [
      {
        icon: ArrowsPointingOutIcon,
        title: 'Общая площадь',
        value: '500 м2'
      },
      {
        icon: CalendarDaysIcon,
        title: 'Период строительства',
        value: '04.24-11.25'
      },
      {
        icon: ChartPieIcon,
        title: 'Минимальная инвестиция',
        value: '€2500'
      },
      {
        icon: CurrencyEuroIcon,
        title: 'Ожидаемая доходность',
        value: '33% годовых в €'
      }
    ],
    image: require('./images/3.webp'),
    description: "Эксклюзивная вилла с современной архитектурой и роскошным дизайном общей площадью 500 квадратных метров, в одном из самых живописных районов Болгарии в городе Владая, расположенном всего в 6 км от столицы.\n" +
      "Этот уникальный дом будет построен с применением инновационных технологий и высококачественных материалов. Просторные, наполненные светом жилые зоны с потолками вдвое выше стандартных создают ощущение воздушности и открытости пространства.\n" +
      "Интерьер отличается изысканной простотой, сливающейся с элегантностью натуральных отделочных материалов. Кухня-гостиная представляет собой образцовое сочетание функциональности и стиля с интегрированной современной бытовой техникой и центральным островом из натурального камня."
  },
  {
    title: 'комплекс в городе Пловдив',
    type: 'Дома-Виллы',
    features: [
      {
        icon: PresentationChartBarIcon,
        title: 'Участие Alef Estate Bulgaria',
        value: '€50 000'
      },
      {
        icon: BuildingOfficeIcon,
        title: 'Комнаты',
        value: 'от 2х до 5'
      },
      {
        icon: ChartPieIcon,
        title: 'Минимальная инвестиция',
        value: '€2500'
      },
      {
        icon: CurrencyEuroIcon,
        title: 'Ожидаемая доходность',
        value: '29% годовых в €'
      }
    ],
    image: require('./images/4.jpeg'),
    description: <>

      Жилой комплекс в городе Пловдив предлагает здоровую жизнь в уникальном месте с прекрасными видами на природу.
      Жилой комплекс сочетает в себе преимущества городской и сельской жизни, обеспечивает комфортную жизнь в
      современных домах и хороший доступ к центру города и природе возле города.<br/>

      <strong>Детали проекта:</strong><br/>
      Таунхаусы интегрированы в природный каркас. Дизайн проекта дополнен качественным ландшафтным дизайном. Вся
      концепция проекта, его форма и подходы к нему гармонично дополняют окружающую природу и создает приятную атмосферу
      для жизни.

    </>
  }, {
    title: 'Аквапарк “Аква Оазис”',
    type: 'Аквапарк',
    features: [
      {
        icon: RocketLaunchIcon,
        title: 'Аттракционы',
        value: 'Много проектов'
      },
      {
        icon: ChartBarIcon,
        title: 'LTV',
        value: '48%'
      },
      {
        icon: ChartPieIcon,
        title: 'Минимальная инвестиция',
        value: '€2500'
      },
      {
        icon: CurrencyEuroIcon,
        title: 'Ожидаемая доходность',
        value: '24% годовых в €'
      }
    ],
    image: require('./images/5.jpeg'),
    description: <>

      Аквапарк “Аква Оазис” в городе Перник предложит вам уникальную возможность убежать от городской суеты и погрузит
      вас в захватывающее приключение, полное водных каскадов, ярких горок и увлекательных аттракционов для всей
      семьи.<br/>

      Насладитесь спокойным отдыхом в нашем огромном бассейне или отправьтесь на поиск адреналина на самых смелых
      горках, где скорость и веселье не знают границ.Для маленьких гостей у нас есть специальные зоны с безопасными
      аттракционами и водными играми, где они могут весело провести время, не переживая за свою безопасность.

    </>
  },

]
export default objectsList