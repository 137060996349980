import {CloseButton, Dialog, DialogPanel, DialogTitle, Transition, TransitionChild} from '@headlessui/react'
import {XMarkIcon} from "@heroicons/react/24/outline";
import {cn} from "@/libs/utils";

export default function Modal({isOpen, close, title, children, size = 'md'}) {
  const sixes = {
    'xs': 'max-w-xs',
    'sm': 'max-w-sm',
    'md': 'max-w-md',
    'lg': 'max-w-lg',
    'xl': 'max-w-xl',
    '2xl': 'max-w-2xl',
    '3xl': 'max-w-3xl',
    '4xl': 'max-w-4xl',
    '5xl': 'max-w-5xl',
    '6xl': 'max-w-6xl',
    'screen': 'max-w-screen',
  }
  return (
    <>
      <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-[9999] focus:outline-none" onClose={close}>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-white/5 backdrop-blur-xl ">
            <div className="flex min-h-full items-center justify-center p-4 relative">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 transform-[scale(95%)]"
                enterTo="opacity-100 transform-[scale(100%)]"
                leave="ease-in duration-500"
                leaveFrom="opacity-100 transform-[scale(100%)]"
                leaveTo="opacity-0 transform-[scale(95%)]"
              >
                <DialogPanel className={cn(
                  "w-full rounded-2xl bg-white p-5 lg:p-6 relative",
                  sixes[size]
                )}>
                  <CloseButton
                    className={'p-2 rounded-full hover:bg-dark-200 absolute top-1 right-1 z-10 backdrop-blur transition-all duration-500'}>
                    <XMarkIcon className={'w-5 h-5'}/>
                  </CloseButton>
                  {title && (
                    <DialogTitle as="h3" className="text-lg mb-2 ">
                      {title}
                    </DialogTitle>
                  )}

                  {children}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
