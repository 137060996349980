import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}


export function useGetYmCode() {
  const urlParams = new URLSearchParams(window.location.search);
  const ymCode = urlParams.get('m');
  if (ymCode) {
    localStorage.setItem('ymCode', ymCode);
  }
  const ymCodeFromStorage = localStorage.getItem('ymCode');
  return ymCode || ymCodeFromStorage;
}