import Modal from "@/components/ui/modal";
import Form from "@/components/ui/form";
import React from "react";
import ObjectItemFeatureList from "./ObjectItem/ObjectItemFeatureList";
import ObjectItemImg from "@/components/Objects/ObjectItem/ObjectItemImg";
import ObjectItemHeader from "@/components/Objects/ObjectItem/ObjectItemHeader";

const ObjectModal = ({close, object,isOpen}) => {
  if (!object) return
  return (
    <Modal  isOpen={isOpen} size={'6xl'} close={close}>
      <div className={'grid grid-cols-1 lg:grid-cols-3 gap-6'}>


        <div className={'lg:col-span-2'}>
          <ObjectItemImg imageClasses={'h-80'} object={object}/>
          <ObjectItemHeader object={object} className={'mb-3'}/>
          <p className={'text-sm text-dark-500'}>
            {object.description}
          </p>
        </div>
        <div>

          <ObjectItemFeatureList object={object}/>
          <h3 className={'text-xl lg:text-2xl mt-6'}>
            Заполните форму для получения подробной информации
          </h3>
          <Form buttonText={'Получить информацию'} className={'mt-2'} slug={object.title}/>

        </div>
      </div>

    </Modal>
  );
};

export default ObjectModal;