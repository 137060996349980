import Container from "@/components/ui/container";
import logo from "@/assets/logo.png";
import CallToAction from "@/components/common/CallToAction";
import Form from "@/components/ui/form";
import React from "react";
import {ClockIcon, MapPinIcon, PhoneIcon} from "@heroicons/react/24/outline";

const Footer = () => {
  return (
    <header className="py-16 relative bg-gradient-to-b  from-primary-500 to-primary-700">
      <Container className={'z-10 relative flex lg:space-x-4 items-center justify-center lg:flex-row flex-col'}>
        <div className={'lg:w-2/3 w-full mb-16'}>
          <img src={logo} alt="logo" className={'w-24 mb-4 grayscale'}/>
          <h2 className="text-4xl mb-8 text-white">
            Поможем вам сделать правильное решение по инвестициям в недвижимость в Болгарии
          </h2>
          <p className={'text-primary-100 text-lg mb-8'}>
            Болгария - это страна с уникальным климатом, красивой природой и развитой инфраструктурой. Инвестиции в
            недвижимость в Болгарии - это выгодное вложение средств в недвижимость, которое приносит стабильный доход и
            обеспечивает долгосрочное сохранение капитала.
          </p>
          <ul className={'text-white space-y-2'}>
            <li className={'flex items-center space-x-1'}>
              <MapPinIcon className={'w-6 h-6 text-accent-500'}/>
              <span> SOF46 Болгария, София</span>
            </li>
            <li className={'flex items-center space-x-1'}>
              <ClockIcon className={'w-6 h-6 text-accent-500'}/>
              <span> Пон-Суб: 9:00–18:00 Воскресенье ЗАКРЫТО</span>
            </li>
            <li className={'flex items-center space-x-1'}>
              <PhoneIcon className={'w-6 h-6 text-accent-500'}/>
              <span> +359 24-372-645</span>
            </li>

          </ul>


        </div>
        <div className={'lg:w-1/3 rounded-lg bg-white w-full py-6 px-5'}>
          <CallToAction title={'Получите консультацию по инвестициям в недвижимость в Болгарии'}/>
          <Form className='mt-2' slug={'Footer'} buttonText={'Получить консультацию'}/>
        </div>
      </Container>
    </header>
  );
};

export default Footer;