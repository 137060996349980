import {cn} from "@/libs/utils";

const ObjectItemHeader = ({object,titleClasses,className}) => {
  return (
    <div className={cn('flex items-center justify-between mb-5',className)}>
      <h3 className={cn('text-xl',titleClasses)}>{object.title}</h3>
      {object.price && (
      <span
        className={'rounded-full bg-accent-500 text-sm py-1 px-2.5 text-white shadow-lg shadow-accent-500/50'}>
        {object.price}
      </span>
      )}
    </div>
  );
};

export default ObjectItemHeader;