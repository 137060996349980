import {cn} from "@/libs/utils";

const Input = ({className,...props}) => {
  return (
    <input {...props}
           className={cn(
             "form-input",
             'rounded-lg border border-neutral-300 focus:border-primary-500 focus:ring-primary-500 focus:ring-1',
             className
           )}
    />
  );
};

export default Input;