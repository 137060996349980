import ObjectItemFeature from "./ObjectItemFeature";
import {cn} from "@/libs/utils";

const ObjectItemFeatureList = ({object ,className}) => {
  return (
    <ul className={cn('grid gap-2 grid-cols-2',className)}>
       {object?.features?.map((feature,index) => (
          <ObjectItemFeature
            key={index} {...feature}
          />
        ))}
    </ul>
  );
};

export default ObjectItemFeatureList;