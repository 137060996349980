import React from "react";
import {cn} from "@/libs/utils";

const CallToActionFeaturesElement = ({icon: Icon, children,className, ...rest}) => {
  return (
    <li {...rest} className={cn('flex items-center space-x-1',className)}>
      <Icon className={'size-5 text-accent-500'}/>
      <span >
        {children}
      </span>
    </li>
  );
};

export default CallToActionFeaturesElement;