const TeamItem = ({person}) => {
  return (
    <li key={person.name}>
      <div className="flex items-center gap-x-6">
        <img className="h-16 w-16 rounded-full shadow-lg" src={person.image} alt=""/>
        <div>
          <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
          <p className="text-sm font-semibold leading-6 text-accent-500">{person.position}</p>
        </div>
      </div>
    </li>
  );
};

export default TeamItem;