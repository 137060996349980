import {CalendarIcon, ChartPieIcon, CheckBadgeIcon} from "@heroicons/react/24/outline";
import React from "react";
import CallToActionFeaturesElement from "@/components/common/CallToAction/CallToActionFeaturesElement";

const CallToActionFeatures = () => {
  const features = [
    {
      icon: CheckBadgeIcon,
      text: 'Гарантия доходности'
    }, {
      icon: CalendarIcon,
      text: 'Выплата дивидендов через 1 месяц'
    },
    {
      icon: ChartPieIcon,
      text: 'Вкладываем в объект до 35% собственных средств'
    },
  ];
  return (
    <ul className={'text-sm grid grid-cols-1 gap-1'}>
      {features.map((feature, index) => (
        <CallToActionFeaturesElement key={index} icon={feature.icon}>{feature.text}</CallToActionFeaturesElement>
      ))}
    </ul>
  );
};

export default CallToActionFeatures;