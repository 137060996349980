import { cn } from "@/libs/utils";
import Button from "./button";

const ButtonPrimary = ({ children, className, ...rest }) => {
  return (
    <Button
      className={cn(
        "bg-gradient-to-br from-primary-500 to-primary-700 text-white",
        "hover:opacity-90",
        "shadow-xl shadow-primary-500/50",
        className
      )}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ButtonPrimary;
