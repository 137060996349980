import Container from "@/components/ui/container";

const About = () => {
  return (
    <section className={'pt-16 pb-8'}>
      <Container>
        <h2 className={'text-4xl'}>О компании Alef Estate</h2>
        <p className={'text-dark-400 mt-6'}>«Откройте дверь к новым возможностям в мире недвижимости с Alef Estate! Мы приглашаем вас в захватывающее путешествие инвестирования в прекрасные болгарские недвижимые объекты. Ищите качественные инвестиции? Мы предлагаем вам уникальные возможности для приобретения жилья в самых живописных уголках Болгарии. Надежность, прозрачность и высокая доходность — это те характеристики, которые отличают нас на рынке. Присоединяйтесь к Alef Estate и осуществите свои мечты о недвижимости в Болгарии прямо сейчас!»</p>
      </Container>
    </section>
  );
};

export default About;