import React from 'react';
import Container from "@/components/ui/container";
import {BanknotesIcon, ChartBarSquareIcon, DocumentMagnifyingGlassIcon} from "@heroicons/react/24/outline";

const Services = () => (
  <section className={'py-16'}>
    <Container>
      <h2 className={'text-4xl'}>Наши услуги</h2>
      <p className={'text-dark-400'}>Мы предлагаем широкий спектр услуг в области недвижимости</p>

      <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mt-10'}>
        <div className={'bg-white'}>
          <div className={'shadow-xl shadow-primary-500/50 mb-2 bg-gradient-to-br from-primary-500 to-primary-700 inline-block p-3 rounded-full'}>
            <DocumentMagnifyingGlassIcon strokeWidth={1} className={'w-8 h-8 text-white'}/>
          </div>
          <h3 className={'text-xl'}>Исследование рынка</h3>
          <p className={'text-dark-500'}>Оценка жизнеспособности, стабильности и прибыльности бизнеса, суббизнеса или проекта.</p>
        </div>
        <div className={'bg-white'}>
          <div className={'shadow-xl shadow-primary-500/50 mb-2 bg-gradient-to-br from-primary-500 to-primary-700 inline-block p-3 rounded-full'}>
            <ChartBarSquareIcon strokeWidth={1} className={'w-8 h-8 text-white'}/>
          </div>
          <h3 className={'text-xl'}>Управление инвестициями</h3>
          <p className={'text-dark-500'}>От стратегического планирования до анализа рисков и мониторинга.</p>
        </div>
        <div className={'bg-white'}>
          <div className={'shadow-xl shadow-primary-500/50 mb-2 bg-gradient-to-br from-primary-500 to-primary-700 inline-block p-3 rounded-full'}>
            <BanknotesIcon strokeWidth={1} className={'w-8 h-8 text-white'}/>
          </div>
          <h3 className={'text-xl'}>Продажи & торговля</h3>
          <p className={'text-dark-500'}>Мы предоставляем уникальные решения для успешного вложения в строительный бизнес.</p>
        </div>

      </div>
    </Container>
  </section>
);

export default Services;