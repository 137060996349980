import Container from "@/components/ui/container";
import TeamItem from "@/components/Team/TeamItem";
import elena from './images/image 2.jpg'
import alex from './images/image 3.jpg'
import yuri from './images/image 4.jpg'
import pavel from './images/image 5.jpg'


const people=[
  {
    name: 'Елена М.',
    position: 'Менеджер по подбору недвижимости',
    image: elena
  },
  {
    name: 'Александр П.',
    position: 'Менеджер по рекламе и маркетингу',
    image: alex
  },
  {
    name: 'Юрий С.',
    position: 'Консультант по инвестиционным депозитам',
    image: yuri
  },
  {
    name: 'Павел Н.',
    position: 'Генеральный директор компании',
    image: pavel
  }
]
const Team = () => {

  return (
    <>
    <section className="pb-16 pt-8 border-t border-gray-200">
      <Container className={'grid gap-8 xl:grid-cols-3'}>
        <div className="max-w-2xl">
          <h2 className="text-xl tracking-tight ">Познакомьтесь с нашей командой</h2>
          <p className="mt-4 text-sm text-dark-400">
            Alef Estate привержена принципам прозрачности, надежности и профессионализма во всех аспектах работы с клиентами. Присоединяйтесь к нам сегодня и откройте для себя новые возможности в мире недвижимости!
          </p>
        </div>
        <ul className="grid gap-8 sm:grid-cols-2 xl:col-span-2">
          {people.map((person) => (
            <TeamItem key={person.name} person={person} />
          ))}
        </ul>
      </Container>
    </section>
      </>
  );
};

export default Team;