import IntlTelInput from 'intl-tel-input/reactWithUtils';

import "intl-tel-input/styles";
import {cn} from "@/libs/utils";
import { useState} from "react";

const errorMap = [
  "Неправильный номер",
  "Неверный код страны",
  "Слишком короткий",
  "Слишком долгий",
  "Неправильный номер",
];

const PhoneInput = ({className, value, setValue,inputRef}) => {
  const [errorCode, setErrorCode] = useState(null);
  const [number, setNumber] = useState('');
  const [isValid, setIsValid] = useState(false);

  const onChaneNumberHandler = (number) => {
    setNumber(number);
    if (number && isValid) {
      setValue(number);
    } else {
      setValue('');
    }

  }
  const showNotice = () => {
    if (isValid) {
      return `Все отлично!`;
    } else {
      return errorMap[errorCode || 0] || "Неправильный номер";
    }
  }

  return (
    <>
      <IntlTelInput
        initialValue={value}
        ref={inputRef}
        onChangeNumber={onChaneNumberHandler}
        onChangeValidity={setIsValid}
        onChangeErrorCode={setErrorCode}
        inputProps={
          {
            required: !isValid,
            className: cn(
              "rounded-lg border border-neutral-300 focus:border-primary-500 focus:ring-primary-500 focus:ring-1",
              className,
              {"focus:border-red-500 border-red-500 focus:ring-red-500": number && !isValid},
              {'focus:border-green-500 border-green-500 focus:ring-green-500': isValid}
            )
          }
        }

        initOptions={{
          onlyCountries: ['ru','by', 'kz'],
          initialCountry: "ru",
          containerClass: "w-full",
          separateDialCode: true,
        }}
      />
      {number &&
        (<small className={cn(
          'absolute right-0 text-xs top-1.5',
          isValid ? 'text-green-500' : 'text-red-500'
        )}>
          {showNotice()}
        </small>)
      }
    </>
  );
};

export default PhoneInput;