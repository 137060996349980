import Header from "@/components/Header";
import Services from "@/components/Services";
import PermanentResidence from "@/components/PermanentResidence";
import About from "@/components/About";
import Team from "@/components/Team";
import Objects from "@/components/Objects";
import Footer from "@/components/Footer";
import {YMInitializer} from "react-yandex-metrika";
import {useGetYmCode} from "@/libs/utils";

function App() {
  const ymCode = useGetYmCode();

  return (
    <>
      {ymCode && <YMInitializer accounts={[Number(ymCode)]} options={{webvisor: true}} version="2"/>}

      <Header/>
      <Services/>
      <PermanentResidence/>
      <About/>
      <Team/>
      <Objects/>
      <Footer/>
    </>
  )
}

export default App;
