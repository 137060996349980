import Modal from "@/components/ui/modal";
import React from "react";
import Button from "@/components/ui/button";
import CallToAction from "@/components/common/CallToAction";
import Form from "@/components/ui/form";

const PermanentResidenceModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const close = () => setIsOpen(false);

  const open = () => setIsOpen(true);
  return (
    <>
      <Button
        onClick={open}
        className={'mt-8 shadow-lg py-4 px-8 bg-white text-primary-500 hover:text-white hover:bg-transparent border-2 border-white'}>
        Узнать подробнее
      </Button>


      <Modal isOpen={isOpen} size={'md'} close={close}>
        <CallToAction title={'Подробнее о получении видов на жительство вы можете узнать у наших менеджеров.'}/>
        <Form className='mt-2' slug={'ВНЖ'} buttonText={'Получить консультацию'}/>
      </Modal>
    </>
  )
};

export default PermanentResidenceModal;