import {cn} from "@/libs/utils";

const ObjectItemImg = ({object,imageClasses}) => {
  return (
    <div
      className={'relative rounded-2xl overflow-hidden shadow-xl mb-6 group-hover:shadow-2xl transition-all duration-500'}>
      <img loading={'lazy'} src={object.image} alt={object.location}
           className={cn(
             'w-full h-64 object-cover rounded-2xl group-hover:scale-105 transition-all duration-1000',
             imageClasses
           )}/>
      <p
        className={'text-white p-4 bg-gradient-to-b from-transparent w-full to-dark-950 absolute bottom-0 left-0'}>Тип
        недвижимости: {object.type}</p>
    </div>
  );
};

export default ObjectItemImg;