import {cn} from "@/libs/utils";

const ObjectItemFeature = ({icon: Icon, title, value,className}) => {
  if (!value) return null;
  return (
    <li className={cn('flex flex-col',className)}>
       <div className={'flex items-center space-x-1'}>
         <Icon className="w-5 h-5 text-accent-500"/>
        <span>{value}</span>
       </div>
        <small className={'text-xs text-dark-400'}>{title}</small>
    </li>
  );
};

export default ObjectItemFeature;