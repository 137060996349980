import React from "react";
import CallToActionFeatures from "./CallToActionFeatures";

const CallToAction = ({title}) => {
  return (
    <>
      <h3 className={'text-primary-800 text-lg font-bold mb-2'}>{title}</h3>
      <CallToActionFeatures/>
    </>
  );
};

export default CallToAction;