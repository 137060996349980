import Container from "@/components/ui/container";
import PermanentResidenceModal from "./PermanentResidenceModal";

const PermanentResidence = () => {
  return (
    <section className={'py-16 bg-gradient-to-br from-primary-500 to-primary-700'}>
      <Container>
        <h2 className={'text-white text-2xl lg:text-4xl mb-4'}>
          При инвестировании в болгарские объекты, вы можете получить вид на жительство за период от 2 до 6 месяцев.
        </h2>
        <p className={'text-lg text-primary-100'}>
          Этот процесс облегчен благодаря нашим партнерским отношениям и опыту в сфере визовой поддержки.
        </p>
        <PermanentResidenceModal/>
      </Container>

    </section>
  );
};

export default PermanentResidence;